// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.caption-text {
    font-size: 14px;
}

.css-1qbdd0n,
.css-1nos2st,
.css-1bmbtuq {
    padding: 5px !important
}

a {
    cursor: pointer
}

.form-group {
    display: flex;
    flex-direction: row;
}

.custom-datepicker {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-datepicker:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 8px rgba(128, 189, 255, 0.6);
}

.datePicker {
    display: flex;
    align-items: center;
}

.transparent-background {
    background-image:
        linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
        linear-gradient(-45deg, #e0e0e0 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #e0e0e0 75%),
        linear-gradient(-45deg, transparent 75%, #e0e0e0 75%);
    background-size: 20px 20px;
    /* Kích thước của các ô vuông */
    background-position: 0 0, 10px 0, 10px -10px, 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.transparent-background img {
    max-width: 100%;
    max-height: 100%;
}

.css-1dur2uz-MuiPaper-root-MuiDialog-paper{
    max-width: 800px!important;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;;;IAGI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI;;;;6DAIyD;IACzD,0BAA0B;IAC1B,+BAA+B;IAC/B,sDAAsD;IACtD,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".caption-text {\n    font-size: 14px;\n}\n\n.css-1qbdd0n,\n.css-1nos2st,\n.css-1bmbtuq {\n    padding: 5px !important\n}\n\na {\n    cursor: pointer\n}\n\n.form-group {\n    display: flex;\n    flex-direction: row;\n}\n\n.custom-datepicker {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ced4da;\n    border-radius: 5px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.custom-datepicker:focus {\n    outline: none;\n    border-color: #80bdff;\n    box-shadow: 0 0 8px rgba(128, 189, 255, 0.6);\n}\n\n.datePicker {\n    display: flex;\n    align-items: center;\n}\n\n.transparent-background {\n    background-image:\n        linear-gradient(45deg, #e0e0e0 25%, transparent 25%),\n        linear-gradient(-45deg, #e0e0e0 25%, transparent 25%),\n        linear-gradient(45deg, transparent 75%, #e0e0e0 75%),\n        linear-gradient(-45deg, transparent 75%, #e0e0e0 75%);\n    background-size: 20px 20px;\n    /* Kích thước của các ô vuông */\n    background-position: 0 0, 10px 0, 10px -10px, 0px 10px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.transparent-background img {\n    max-width: 100%;\n    max-height: 100%;\n}\n\n.css-1dur2uz-MuiPaper-root-MuiDialog-paper{\n    max-width: 800px!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
