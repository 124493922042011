const data3 = [
    [
        "category",
        "brand",
        "product_name",
        "product_description",
        "main_image",
        "image_2",
        "image_3",
        "image_4",
        "image_5",
        "image_6",
        "image_7",
        "image_8",
        "image_9",
        "gtin_type",
        "gtin_code",
        "property_value_1",
        "property_value_2",
        "property_1_image",
        "parcel_weight",
        "parcel_length",
        "parcel_width",
        "parcel_height",
        "delivery",
        "price",
        "quantity",
        "seller_sku",
        "size_chart",
        "product_property/100157",
        "product_property/100198",
        "product_property/100393",
        "product_property/100394",
        "product_property/100395",
        "product_property/100397",
        "product_property/100398",
        "product_property/100399",
        "product_property/100400",
        "product_property/100401",
        "product_property/100403",
        "product_property/101619",
        "product_property/101395",
        "product_property/101398",
        "product_property/101400",
        "product_property/101397",
        "product_property/101610",
        "product_property/100216",
        "product_property/101611",
        "product_property/101623",
        "product_property/101624",
        "product_property/101625",
        "product_property/101614",
        "qualification/1729439947134305535",
        "qualification/8647636475739801353",
        "aimed_product_status"
    ],
    [
        "V3",
        "create_product",
        "imperial",
        "category_v2",
        null,
        "202408050950552A22134E4511083553E6",
        "normal_file"
    ],
    [
        "Category",
        "Brand",
        "Product Name",
        "Product Description",
        "Main Product Image",
        "Product Image 2",
        "Product Image 3",
        "Product Image 4",
        "Product Image 5",
        "Product Image 6",
        "Product Image 7",
        "Product Image 8",
        "Product Image 9",
        "Identifier Code Type",
        "Identifier Code",
        "Variation 1",
        "Variation 2",
        "Variant Image",
        "Package Weight(lb)",
        "Package Length(inch)",
        "Package Width(inch)",
        "Package Height(inch)",
        "Delivery options",
        "Retail Price (Local Currency)",
        "Quantity in ERIC TRUONG",
        "Seller SKU",
        "Size Chart",
        "Material",
        "Pattern",
        "Neckline",
        "Clothing Length",
        "Sleeve Length",
        "Season",
        "Style",
        "Fit",
        "Stretch",
        "Care Instructions",
        "Waist Height",
        "Other Dangerous Goods or Hazardous Materials",
        "CA Prop 65: Repro. Chems",
        "Reprotoxic Chemicals",
        "CA Prop 65: Carcinogens",
        "Carcinogen",
        "Contains Batteries or Cells?",
        "Battery type",
        "How Batteries Are Packed",
        "Number of Batteries or cells",
        "Battery or Cell Capacity in Wh",
        "Battery or Cell Capacity in grams",
        "Battery or Cell Weight in grams",
        "Safety Data Sheet (SDS) for products with batteries",
        "Safety Data Sheet (SDS) for other dangerous goods or hazardous materials",
        "Product Status"
    ],
    [
        "Mandatory",
        "Optional",
        "Mandatory",
        "Mandatory",
        "Mandatory",
        "Optional",
        "Optional",
        "Optional",
        "Optional",
        "Optional",
        "Optional",
        "Optional",
        "Optional",
        "Optional",
        "Optional",
        "Conditionally mandatory ",
        "Optional",
        "Optional",
        "Mandatory",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Optional",
        "Mandatory",
        "Conditionally mandatory ",
        "Optional",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Conditionally mandatory ",
        "Optional"
    ],
    [
        "Select the category that matches the product from the drop-down list. \r\n\r\nListing prohibited or restricted products, or assigning products to the wrong category may result in TikTok Shop taking enforcement action on both your listings and your seller account.",
        "Select the brand that matches the product from the drop-down list.\r\nIf no brand is selected, it will be left empty and considered to have no brand.",
        "• Product name must be less than 255 characters.\r\n• Enter the title of your product.\r\n• If there are multiple SKUs, keep the titles consistent.",
        "Provide a detailed description of the product, such as product specifications, materials, box content and so on.\r\nA structured and informative product description can help customers make purchase decisions.\r\nIt's recommended to provide 3-5 selling points and each one can be described within 250 characters and several images, like feel, usage and benefits, etc.\r\nMake it easier to read by good use of segmentation, bolding, numbering, etc.",
        "Copy the product image URL here. This image will be the cover image of the product.\r\n• Main product image aspect ratio: Between 3:4 and 4:3.\r\n• Picture pixel range: Between 100×100 px and 1200×1200 px, the higher the clearer.\r\nIt's recommended to provide 6 images to communicate the selling points and features of your products, inform and interest customers, and reinforce your brand.\r\nIt's recommended to use a white background image as the first image instead of using an image with other elements.\r\nGood images make it easy for customers to evaluate the product. Images should be clear, informative, and attractive.",
        "Add the URLs of 4 additional images that complement the main product.\r\n \r\nWe recommend you upload images to the media center first. After uploading the images, copy the URL links and add them to the appropriate column.\r\n \r\nRefer to the sheet \"Image\" in this workbook for more details.",
        "Add the URLs of 4 additional images that complement the main product.\r\n \r\nWe recommend you upload images to the media center first. After uploading the images, copy the URL links and add them to the appropriate column.\r\n \r\nRefer to the sheet \"Image\" in this workbook for more details.",
        "Add the URLs of 4 additional images that complement the main product.\r\n \r\nWe recommend you upload images to the media center first. After uploading the images, copy the URL links and add them to the appropriate column.\r\n \r\nRefer to the sheet \"Image\" in this workbook for more details.",
        "Add the URLs of 4 additional images that complement the main product.\r\n \r\nWe recommend you upload images to the media center first. After uploading the images, copy the URL links and add them to the appropriate column.\r\n \r\nRefer to the sheet \"Image\" in this workbook for more details.",
        "Add the URLs of 4 additional images that complement the main product.\r\n \r\nWe recommend you upload images to the media center first. After uploading the images, copy the URL links and add them to the appropriate column.\r\n \r\nRefer to the sheet \"Image\" in this workbook for more details.",
        "Add the URLs of 4 additional images that complement the main product.\r\n \r\nWe recommend you upload images to the media center first. After uploading the images, copy the URL links and add them to the appropriate column.\r\n \r\nRefer to the sheet \"Image\" in this workbook for more details.",
        "Add the URLs of 4 additional images that complement the main product.\r\n \r\nWe recommend you upload images to the media center first. After uploading the images, copy the URL links and add them to the appropriate column.\r\n \r\nRefer to the sheet \"Image\" in this workbook for more details.",
        "Add the URLs of 4 additional images that complement the main product.\r\n \r\nWe recommend you upload images to the media center first. After uploading the images, copy the URL links and add them to the appropriate column.\r\n \r\nRefer to the sheet \"Image\" in this workbook for more details.",
        "Select the identifier code type",
        "Please enter the identifier code.\r\nFor more infomation:https://shop-academy-us.tiktok.com/university/essay?knowledge_id=10012430&role=1&from=feature_guide&identity=1",
        "Add at least 1 product variation to this column (for example, a color). Limit each entry to 35 characters.\r\n\r\nVariations must have the same product name and package dimensions. To see examples, view the sheet titled \"Example.\"",
        "Complete this column only if your product has additional variations on top of the previous \"Variation\" column.\r\n\r\nAdd additional variations, such as sizes available in each color. Limit entry to 50 characters.\r\n\r\nFor example, a product in red and black, with each variation available in two sizes (small and medium), will require entries in four rows.\r\n\r\nVariations must have the same product name and package dimensions. To see examples, view the sheet titled \"Example.\"",
        "Add the image URL for the variation in this column. Make sure the image represents the product variation.\r\n \r\nWe recommend you upload images to the media center first. After uploading the images, copy the URL links and add them to the appropriate column.\r\n \r\nRefer to the sheet \"Image\" in this workbook for more detailed information.",
        "The weight of the package, not the product itself.\r\nEnter the weight of the product and its packaging, not just the product's weight.\r\n\r\nThe weight and dimensions of the first SKU are used to calculate the shipping fee and method for all variations, even when variations show different values.",
        "The package dimensions of the first SKU are used to calculate the shipping fee and method for all variations, even when variations show different values. Make sure all SKU's dimensions are accurate.\r\n\r\nCells with red borders are required.",
        null,
        null,
        "The delivery options for this product are the same as the delivery options for the shop. ",
        "Enter the price of the product or variant. DO NOT include any currency symbols.",
        "Enter the quantity for each product in its warehouse cell. Click \"+\" to see all MWH. All SKUs must be assigned to at least one warehouse, with the first warehouse (highlighted in red) being mandatory.\r\n\r\nGray cells indicate quantity limitations. These products default to a quantity of 1 and are automatically assigned to the first warehouse.\r\n\r\nAfter publication, default quantities can be edited in Seller Center (PC version). Editing this template doesn't change default quantities. For more information, visit Academy.\r\nFor product categories with a limitation on quantity, the cells are grayed out. The quantity will be set as 1 by default when publishing. If you have multiple warehouses, the first warehouse will be used here automatically. Any edits to the quantity information on this template will not take effects. You can only edit the quantity in Seller Center on PC.\r\nFor more information, please visit TikTok Shop Academy.",
        "Identifier of the product or variant. This is used to quickly find the item in other systems.",
        "Copy the URL or enter the size chart template ID here.\r\nThis field is not supported for some product categories. If you enter information for a product in an affected category, the information entered for this field won't be published. Other information will be published as normal. ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use drop-down menu to select value or input custom value",
        "[Input type]:Use drop-down menu to select value or input custom value",
        "[Input type]:Use drop-down menu to select value or input custom value",
        "[Input type]:Use drop-down menu to select value or input custom value",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Use the drop-down menu to select value ",
        "[Input type]:Enter custom values",
        "[Input type]:Enter custom values",
        "[Input type]:Enter custom values",
        "[Input type]:Enter custom values",
        "Upload the SDS for products with batteries. Find them on the manufacturer's website. View example.",
        "Upload the SDS for any other hazardous materials or dangerous goods in your product. Example.",
        "Dropdown to select product status. TikTok supports publishing of active and draft products. Drafts can be edited in bulk online."
    ]
]

export default data3

